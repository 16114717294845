<template>
    <v-layout class="flex-column">
        <!-- BODY -->
        <core-body :shown="true" bgcolor="transparent" :loading="body.showLoading" column="x12" :msgshown="body.alert.show"
            :msgtype="body.alert.type" :msg="body.alert.message" :shownd="body.dialog.show" v-on:funcmsgshown="body_alertShow"
            v-on:funcshownd="body_dialogShow">
            <!-- WIDGET -->
            <template slot="content" >
                <v-row class="py-0">
                    <v-col cols="5" style="padding: 0px 0px 0px 0px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#414042" styles="" textColor="#414042" textStyles="margin:2px 0px -10px 0px!important;"
                            :bordered="false" :headcount="widget.countDPT" subheadcount=" Orang"
                            title="DPT" :img="require('@/assets/svg/user/voters.svg')"
                            v-on:action="widget_filterPemilih()" v-on:action1="widget_filterPemilih('1')" v-on:action2="widget_filterPemilih('0')"
                            :addInfo="[
                                { text: 'Voters', value: `${widget.countVoters}`, bgColor: `#45D738`, color: '#45D738', },
                                { text: 'NonVoters', value: `${widget.countNonVoters}`, bgColor: `#C8001D`, color: '#C8001D' }
                        ]" />
                    </v-col>
                    <v-col cols="3" style="padding: 0px 8px 0px 8px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#45D738" styles="" textColor="#45D738" textStyles="margin:2px 0px -10px 0px!important;"
                        v-on:action="widget_filterPemilih('1')" :bordered="false" :headcount="widget.countPorsen"
                        subheadcount=" %" title="Kemenangan" :img="require('@/assets/svg/confirm.svg')" />
                    </v-col>
                    <v-col cols="4" style="padding: 0px 0px 0px 0px !important;margin-bottom: 0px !important;">
                        <core-widget bgColor="#FFB727" styles="" textColor="#FFB727" textStyles="margin:2px 0px -10px 0px!important;"
                        v-on:action="widget_filterDuplicate()" :bordered="false" :headcount="widget.countDuplicate"
                        subheadcount=" Orang" title="Duplicate" :img="require('@/assets/svg/user/user.svg')" />
                    </v-col>
                </v-row>
            </template>
            <template slot="content">
                <v-flex class="v-toolbar toolbar-fleet-table toolbar-table-asset">
                    <div class="py-4 px-2" style="padding-bottom:0px!important;">
                        <v-row>
                            <!-- SEARCH -->
                            <v-col cols="12" sm="4" class="mb-0">
                                <!-- :newCols="table.search.colDevice" :titleCols="2" classForm="" :multiple="false" -->
                                <core-searchParam 
                                    :datas="table.tblHeader" :selectData="table.search.selected" :v_model="table.search.value" v-on:getSelected="search_searchBy"
                                    v-on:getValue="table_searchValue" />
                            </v-col>
                            <v-col cols="12" sm="2" class="mb-0">
                                <v-select v-model="table.filter.selectedKec" outlined dense :items="table.filter.listKecamatan"
                                item-value="value" item-text="text" menu-props="auto" label="Kecamatan" hide-details
                                :single-line="false"></v-select>
                            </v-col>
                            <v-col cols="12" sm="2" class="mb-0">
                                <v-select v-model="table.filter.selectedKel" outlined dense :items="table.filter.listKelurahan"
                                item-value="value" item-text="text" menu-props="auto" label="Kelurahan" hide-details
                                :single-line="false"></v-select>
                            </v-col>
                            <v-col cols="12" sm="2" class="mb-0">
                                <v-select v-model="table.filter.selectedTps" outlined dense :items="table.filter.listTps"
                                item-value="value" item-text="text" menu-props="auto" label="TPS" hide-details
                                :single-line="false"></v-select>
                            </v-col>
                            <!-- DOWNLOAD -->
                            <v-col cols="12" sm="2" class="mb-0">
                                <div style="text-align:right;">
                                    <v-btn class="btn-export-xl color-danger" title="Download"
                                        @click="form_downloadData('xlsx')"><v-icon>mdi-microsoft-excel</v-icon> Download
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-flex>
                <!-- TABLE -->
                <core-table-fixed-outer-grid class="px-2 table-fleet-management" :tblHeader="table.tblHeader" backgroundHeader="#C8001D" 
                    :items="table.tblData" id="tbl_exporttable_to_xls" :searchby="table.search.selected" :search="table.search.value" 
                    item-key="iVotersID" :height="'auto'">
                    <!-- CUSTOME COLUMN & VALUE -->
                    <template v-slot:[`item.iVoters`]="{ item }">
                        <div :style="`text-align:center;`">
                            <v-icon v-if="item.iVoters==1" :title="item.sCarrier" color="#45D738">mdi-account</v-icon>
                        </div>
                    </template>
                    <!-- ACTION ROW -->
                    <template v-slot:[`item.actions`]="{ item }">
                        <div :style="`text-align:center;`">
                            <v-icon @click="table_showDetail(item)" title="Set as Voters" color="#C8001D">mdi-account-edit</v-icon>
                        </div>
                    </template>
                </core-table-fixed-outer-grid>
            </template>
        </core-body>
        <!-- POPUP -->
        <v-main>
            <v-layout align-center justify-center style="border-radius:15px 15px 0px 0px">
                <v-dialog persistent v-model="form.detail.show" scrollable width="1200" style="position: absolute">
                    <Detail v-if="form.detail.show" :modeEdit="form.detail.edited" :data="form.detail.dataDetail"
                        v-on:closePopUp="detail_btnClose()" />
                </v-dialog>
            </v-layout>
        </v-main>
        <!-- FOOTER -->
		<core-footer :shown="true" :bgcolor="`white`" column="x12"></core-footer>
    </v-layout>
</template>

<script>
import { success, error } from '@/consistencychecker/transactionvalidator/index'
import { numberFormat } from "@/consistencychecker/formvalidator/format"
import VotersAPI from '@/services/sa/SA_Voter'
import UsersAPI from '@/services/sa/SA_User'
import Detail from './Detail.vue'
import XLSX from '@/plugins/exportExcel'
import { styleHeaderExcel } from '@/consistencychecker/formvalidator/file'

export default {
    components: { Detail },
    metaInfo() {
        return { title: 'Quick Count' }
    },
    name: 'VotersMain',
    data() {
        return {
            body: {
                showLoading: true,
                alert: { show: false, type: '', message: '', },
                dialog: { show: false, detail: false, },
            },
            widget:{
                countDPT:`0`, countVoters:`0`, countNonVoters:`0`,
                countPorsen:`0`, countDuplicate:`0`
            },
            form:{
                detail:{
                    show:false, edited: false,
                    dataDetail:{ title:``,
                        tpsID:0,tpsName:``,kelID:0,kelName:``,kecID:0,kecName:``,
                        iVotersID:null, sName:``, sGender:``, iAge:``,
                        sAddress:``, sRT:``, sRW:``, sKtpNo:``,iCarrier:``,sCarrier:``,
                        listKorlap:[]
                    }
                },
            },
            table:{
                search:{
                    colDevice: window.screen.width >= 600 ? 12 : 6,
                    selected:``, value:``
                },
                filter:{
                    colDevice: window.screen.width >= 600 ? 12 : 6,
                    listKecamatan:[],listKelurahan:[],
                    selectedKec:``, selectedKel:``, selectedTps:``
                },
                tblHeader:this.$store.getters['app/enum'].TH_VOTERS_MAIN,
                tblData:[], listKorlap:[]
            }
        }
    },
    computed: {
        console:() => console,
        window:() => window,
        isLoggedIn(){
            return this.$store.getters['auth/isSessionActive']
        },
        myScope(){
            return this.$store.getters["auth/getSession"]
        }
    },
    created() {
        this.setFilterKec()
        this.getData()
    },
    async beforeMount() {
        // get data user
    },
    async mounted() {

    },
    watch:{
        'table.filter.selectedKec'(val){
            this.body.showLoading = true
            this.getData()
            this.setFilterKel()
        },
        'table.filter.selectedKel'(val){
            this.body.showLoading = true
            this.getData()
            this.setFilterTps()
        },
        'table.filter.selectedTps'(val){
            this.table.search.value = val!=''?`tpsID:${val}`:''
            this.setValueWidget()
        },
    },
    methods: {
        // Handling Default Function
        handleSuccess(data) {
            this.body.alertShow = true
            this.body.alertType = this.$store.getters['app/color']('s')
            this.body.alertMsg = success(data)
            setTimeout(() => { this.body.loading = false }, 1000)
            setTimeout(() => { this.body.alertShow = false }, 3000)
        },
        handleError(err) {
            this.body.alertShow = true
            this.body.alertType = this.$store.getters['app/color']('e')
            this.body.alertMsg = error(err)
            setTimeout(() => { this.body.loading = false }, 1000)
            setTimeout(() => { this.body.alertShow = false }, 3000)
        },
        body_alertShow(){
            this.body.alertShow = false
        },
        body_dialogShow(){
            this.body.dialogShow = false
        },
        search_searchBy(val){
            this.table.search.selected = val
            this.table.search.value = ``
        },
        table_searchValue(val){
            this.table.search.value = val
        },
        // Handling Filter
        widget_filterPemilih(val){
            if (val) this.table.search.value = `iVoters:${parseInt(val)}`
            else this.table.search.value = ``
        },
        widget_filterDuplicate(){
            this.table.search.value = `isDuplicate:1`
        },
        // Handling Form
        form_downloadData(){
            const resultHeader = this.table.tblHeader.filter(x=>x.value!='actions').map((item) => item.text);
            const resultValue = this.table.tblHeader.filter(x=>x.value!='actions').map((item) => item.value);
            let result = [...this.table.tblData].map((item,h) => {
                return resultValue.map((field) => {
                    let items = item[field]
                    if(field==='iVoters'){
                        items = item[field]==1?`YES`:'NO'
                    }
                    return items
                })
            })
            let tgl = new Date();
            let dateNow = `${("0"+tgl.getDate()).substr(-2)}-${("0"+tgl.getMonth()).substr(-2)}-${("0" + tgl.getFullYear()).substr(-2)}`;
            let kelName = this.table.filter.listKecamatan.filter(x=>x.value==this.table.filter.selectedKec)[0].text
            // CONST CONFIG
            const ws = XLSX.utils.json_to_sheet(result);
            const wb = { Sheets: { 'Data Voters': ws }, SheetNames: ['Data Voters'] }
            XLSX.utils.sheet_add_aoa(ws, [resultHeader]);
            ws["A1"].s = styleHeaderExcel.h1, ws["B1"].s = styleHeaderExcel.h1, ws["C1"].s = styleHeaderExcel.h1,
            ws["D1"].s = styleHeaderExcel.h1, ws["E1"].s = styleHeaderExcel.h1, ws["F1"].s = styleHeaderExcel.h1
            XLSX.utils.book_append_sheet(wb, ws)
            XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            XLSX.writeFile(wb, `QuickCount-Voters${kelName} ${dateNow}.xlsx`)
        },
        table_showDetail(item){
            this.form.detail.dataDetail.title = `Set as Voters`
            this.form.detail.dataDetail.tpsID=item.tpsID
            this.form.detail.dataDetail.tpsName=item.tpsName
            this.form.detail.dataDetail.kelID=item.kelID
            this.form.detail.dataDetail.kelName=item.kelName
            this.form.detail.dataDetail.kecID=item.kecID
            this.form.detail.dataDetail.kecName=item.kecName
            this.form.detail.dataDetail.iVotersID=item.iVotersID
            this.form.detail.dataDetail.sName=item.sName
            this.form.detail.dataDetail.sGender=item.sGender
            this.form.detail.dataDetail.iAge=item.iAge
            this.form.detail.dataDetail.sAddress=item.sAddress
            this.form.detail.dataDetail.sRT=item.sRT
            this.form.detail.dataDetail.sRW=item.sRW
            this.form.detail.dataDetail.sKtpNo=item.sKtpNo
            this.form.detail.dataDetail.iCarrier=this.myScope.sRole=='Korlap'?this.myScope.iUserID:item.iCarrier
            this.form.detail.dataDetail.sCarrier=this.myScope.sRole=='Korlap'?this.myScope.sName:item.sCarrier
            this.form.detail.dataDetail.listKorlap=this.body.listKorlap
            this.form.detail.edited = true
            this.form.detail.show = true
        },
        // Handling PopUp Detail
        detail_btnClose(){
            this.body.showLoading = true
            this.form.detail.show = false
            this.getData()
        },
        password_btnClose(){
            this.form.password.show = false
        },
        // Handling Filter
        groupingData(data, key, text){
            let dataTemp=[]
            data.reduce((acc, item)=>{
                if (!acc[item[key]]) {
                    acc[item[key]] = { value: item[key], text:item[text] };
                    dataTemp.push(acc[item[key]])
                }
                return acc;
            }, {})
            return dataTemp
        },
        setFilterKec(){
            let dataWilayah = localStorage.getItem('dataWilayah')
            if(dataWilayah){
                let dataTempKec = JSON.parse(dataWilayah).map(x=>{ return {value:x.kecID, text:x.kecName} })
                if(this.myScope.sRole=='Korlap'){
                    dataTempKec = dataTempKec.filter(x=>x.value==this.myScope.iSubDistrictID)
                }
                let dataKec = this.groupingData(dataTempKec, 'value', 'text')
                this.table.filter.listKecamatan=dataKec
                this.table.filter.selectedKec=dataKec[0].value
                this.setFilterKel()
            }
        },
        setFilterKel(){
            let dataWilayah = localStorage.getItem('dataWilayah')
            if(dataWilayah){
                let dataTempKel = JSON.parse(dataWilayah).filter(x=>x.kecID==this.table.filter.selectedKec).map(x=>{ return {value:x.kelID, text:x.kelName} })
                if(this.myScope.sRole=='Korlap'){
                    dataTempKel = dataTempKel.filter(x=>x.value==this.myScope.iUrbanVillageID)
                }
                let dataKel = this.groupingData(dataTempKel, 'value', 'text')
                this.table.filter.listKelurahan=dataKel
                this.table.filter.selectedKel=dataKel[0].value
                this.setFilterTps()
            }
        },
        setFilterTps(){
            let dataWilayah = localStorage.getItem('dataWilayah')
            if(dataWilayah){
                let dataTempTps = JSON.parse(dataWilayah).filter(x=>x.kelID==this.table.filter.selectedKel).map(x=>{ return {value:x.tpsID, text:x.tpsName} })
                let dataTps = this.groupingData(dataTempTps, 'value', 'text')
                this.table.filter.listTps=[...[{value:``,text:`All (${dataTps.length})`}], ...dataTps]
                this.table.filter.selectedTps=``
            }
        },
        // Handling Get & Set Data
        getData() {
            this.body.dataTable = []
            this.getDataKorlap(this.table.filter.selectedKec, this.table.filter.selectedKel);
            VotersAPI.getByVoters(this.table.filter.selectedKec, this.table.filter.selectedKel).then(res => {
                if (!res.status) this.handleError(res)
                this.setTableColumn(res.data)
                this.setValueWidget()
                setTimeout(() => { this.body.showLoading = false }, 1000)
            }).catch(err => {
                this.handleError(err)
                console.log('error -> Voters -> getData() :', err);
            })
        },
        getDataKorlap(kecID, kelID){
            UsersAPI.getDataKorlap(kecID, kelID).then(res => {
                if (!res.status) this.handleError(res)
                this.body.listKorlap=res.data.map(x=>{
                    return { value:x.iUserID, text:`${x.sName}-${x.sKtpNo.substr(12,4)}` }
                })
                setTimeout(() => { this.body.showLoading = false }, 1000)
            }).catch(err => {
                this.handleError(err)
                console.log('error -> Korlap -> getData() :', err);
            })
        },
        setTableColumn(data) {
            this.table.tblData = [...data].map(x=>{
                x.isDuplicate = data.filter(y=>y.iVotersID==x.iVotersID&&y.sName==x.sName&&y.sGender==x.sGender&&y.iAge==x.iAge).length>1?1:0
                return x
            })
        },
        setValueWidget(){
            let _data = this.table.tblData
            if(this.table.filter.selectedTps!=''){
                _data = _data.filter(x=>x.tpsID==this.table.filter.selectedTps)
            }
            this.widget.countDPT=numberFormat(_data.length).toString()
            this.widget.countVoters=numberFormat(_data.filter(x=>x.iVoters==1).length).toString()
            this.widget.countNonVoters=numberFormat(_data.filter(x=>x.iVoters==0).length).toString()
            this.widget.countPorsen=parseInt(this.widget.countVoters.replace('.','')) > 0 ? 
                (parseInt(this.widget.countVoters.replace('.',''))/parseInt(this.widget.countDPT.replace('.','')) * 100).toFixed(1) :`0.0`
            this.widget.countDuplicate=numberFormat(_data.filter(x=>x.isDuplicate==1).length).toString()
        },
    }
}
</script>
<style>
.v-data-table-header {
  background-color: #C8001D;
  color: white;
}
.btn-outline {
    height: 40px !important;
}
</style>