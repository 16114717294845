<template>
    <v-card class="primary--text" style="overflow-y: hidden;">
      <core-header :shown="true" bgcolor="" :loading="body.form.loading" column="x12">
      <template slot="content">
        <!-- TITLE -->
        <v-flex xs12 md8 class="px-2" style="margin-top:-14px!important;">
            <core-title color="primary" :title="header.title" />
        </v-flex>
        <!-- TOOLBAR -->
        <v-flex xs12 md4 class="px-2" style="margin-top:-22px !important; padding-right: 0px!important">
          <core-toolbar :btnsetting="false" :btnclose="true" :btnchecklist="false" :btnrefresh="false" :btnreset="false" v-on:closePage="detailClose"></core-toolbar>
        </v-flex>
      </template>
      </core-header>
      <core-alert :flagShow="body.alert.shown" v-on:funcflagShow="body.alert.shown=false" :txtType="body.alert.type" :txtMsgAlert="body.alert.msg" />
      <v-card-text style="padding:2px 2px 0px 10px!important;">
        <v-form ref="form" lazy-validation>
          <div class="form-detail-asset">
            <h3>Normor Induk Kependudukan (NIK) terdiri dari 16 Digit Angka</h3>
            <v-divider></v-divider>
            <v-layout form>
              <v-layout wrap align-center form col-line-one>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Kecamatan" v-model="body.form.kecName" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Kelurahan" v-model="body.form.kelName" dense/>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="TPS" v-model="body.form.tpsName" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Nama" v-model="body.form.sName" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Gender" v-model="body.form.sGender" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Age" v-model="body.form.iAge" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="Address" v-model="body.form.sAddress" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="RT" v-model="body.form.sRT" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" filled shaped :disabled="modeEdit" type="text" label="RW" v-model="body.form.sRW" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <core-entry-field-nested-label-dyn-type1 required :outlined="true" type="text" label="NIK" v-model="body.form.sKtpNo" dense />
                </v-col>
                <v-col cols="12" sm="2" md="4" class="mb-4">
                  <v-select v-model="body.form.iCarrier" outlined :disabled="myScope.sRole=='Korlap'" dense :items="body.form.listKorlap" item-value="value" item-text="text" menu-props="auto" label="Korlap" hide-details :single-line="false"></v-select>
                </v-col>
              </v-layout>
            </v-layout>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="footer-button-asset">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" small @click="detailClose">Cancel</v-btn>
        <v-btn class="btn-submit" small @click="btnSubmit" :disabled="body.form.loading">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  // FUNCTION
  import {success, error} from '@/consistencychecker/transactionvalidator/index'
  import {isValidForm} from '@/consistencychecker/formvalidator/index'
  import VotersAPI from '@/services/sa/SA_Voter'
  
  export default {
    metaInfo () { return { title: 'Quick Count' } },
    name: 'PopUpVoters',
    props: {
      value: {
        type: Boolean,
        default: true
      },
      modeEdit: {
        type: Boolean,
        default: true
      },
      data:{
        type: Object,
        default: { title:``,
          tpsID:0,tpsName:``,kelID:0,kelName:``,kecID:0,kecName:``,
          iVotersID:null, sName:``, sGender:``, iAge:``,
          sAddress:``, sRT:``, sRW:``, sKtpNo:``,iCarrier:``,sCarrier:``,
          listKorlap:[]
        }
      }
    },
    data (){
      return {
        header:{
          title:this.data.title,
        },
        body: {
          form:{
            tpsID:this.data.tpsID,tpsName:this.data.tpsName, kelID:this.data.kelID,kelName:this.data.kelName,
            kecID:this.data.kecID,kecName:this.data.kecName,
            iVotersID:this.data.iVotersID, sName:this.data.sName, sGender:this.data.sGender, iAge:this.data.iAge,
            sAddress:this.data.sAddress, sRT:this.data.sRT, sRW:this.data.sRW, sKtpNo:this.data.sKtpNo, iCarrier:this.data.iCarrier,
            listKorlap:this.data.listKorlap,
            loading:false
          },
          alert:{
            shown: false,
            type: 'success',
            msg: ''
          }
        },
        footer: {
        },
      }
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      myScope(){
        return this.$store.getters["auth/getSession"]
      }
    },
    methods: {
      detailClose(){
        this.$emit('closePopUp');
      },
      btnSubmit(){
        let validDataCheck = {
          tpsID: this.body.form.tpsID,
          sKtpNo: this.body.form.sKtpNo,
          iVotersID: this.body.form.iVotersID,
          sName: this.body.form.sName,
          sGender: this.body.form.sGender,
          iAge: this.body.form.iAge,
          sBy: this.$store.getters["auth/getSession"].iUserID
        }
        let validEntry = isValidForm(validDataCheck)
        if(validEntry.status===false) {
          this.handleError(`Ditolak. Harap melangkapi data *Required!`)
        }else{
          validDataCheck.sCarrier=this.body.form.iCarrier
          VotersAPI.updateDataVoters(validDataCheck, this.body.form.tpsID).then(data=>{ 
            this.handleSuccess(data)
            setTimeout(()=>{this.detailClose();},2000)
          }).catch(err=> {
            this.handleError(err)
          })
        }
      },
      handleSuccess(data){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('s')
        this.body.alert.msg = success(data)
        setTimeout(()=>{this.body.alert.shown=false; this.show=false},3000)
      },
      handleError(err){
        this.body.alert.shown = true
        this.body.alert.type = this.$store.getters['app/color']('e')
        this.body.alert.msg = error(err)
        setTimeout(()=>{this.body.alert.shown=false},3000)
      },
    }
  }
  </script>
  <style>
    .form-detail-asset h3 {
      font-size: 18px !important;
    }
    .status-request {
      background-color: #FFB727 !important;
      border-color: #FFB727 !important;
    }
  
    .detail-asset .v-image{
      max-height: 300px;
      cursor: pointer;
      height: 200px;
      width: 520px;
      margin: auto;
      border: 1px solid #dddddd;
      border-radius: 10px !important;
    }
    
    .detail-asset .v-image__image--contain{
      background-size: cover;
    }
  
    .form-detail-asset .form {
      padding: 8px 0 0 8px;
      margin-right: 8px;
    }
  </style>